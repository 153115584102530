<template>
  <span class="inline-flex rounded-md shadow-sm">
    <component :is="this.as" :href="href" :class="[baseClasses, kindClasses]">
      <slot />
    </component>
  </span>
</template>

<script>
export default {
  props: {
    as: {
      default: 'button'
    },
    href: { required: false, default: null },
    kind: {
      validator(value) {
        return [
          'primary',
          'secondary',
          'white',
          'purple',
          'purpleVariant',
          'danger',
          'cancel',
            'save'
        ].includes(value)
      }
    }
  },
  computed: {
    baseClasses() {
      return 'items-center px-4 py-2 border text-sm rounded-md shadow-sm  focus:outline-none'
    },
    kindClasses() {
      return {
        primary:
            'border-transparent text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
        secondary:
            'border-transparent text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
        white:
            'border-gray-300 uppercase text-gray-700 bg-white w-full hover:bg-gray-50 text-center font-medium py-3 px-1',
        danger:
            'border-transparent text-red-700 bg-red-200 hover:bg-red-300 focus:ring-2 focus:ring-offset-2 focus:ring-red-500 w-full text-center font-medium py-3 px-1',
        save:
            'border-transparent text-green-700 bg-green-200 hover:bg-green-300 focus:ring-2 focus:ring-offset-2 focus:ring-green-500 w-full text-center font-medium py-3 px-1',
        purple:
            'bg-atol-purple uppercase text-white border-atol-purple w-full text-center font-semibold',
        purpleVariant:
            'bg-atol-purple uppercase text-white border-atol-purple w-full text-center font-medium py-3 px-1',
        cancel:
            'bg-white uppercase text-atol-blue border-atol-blue w-full text-center font-medium py-3 px-1'
      }[this.kind]
    }
  }
}
</script>

<style></style>

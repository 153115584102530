<template>
  <div class="about">
    <div class="flex justify-between">
      <select name="categories" id="categories" v-model="category" class="rounded-xl">
        <option value="all" selected="selected">All categories</option>
        <option v-for="cat in categories" :value="cat">{{ cat.name }}</option>
      </select>
      <button
          class="bg-gray-600 border border-transparent rounded-lg text-white px-2 hover:bg-coral"
          @click="open_product = !open_product">
        Add a product
      </button>
      <product-modal v-if="open_product" :open_product="open_product" :categories="categories" @customChange="switchOpen">
        Add a product
      </product-modal>
    </div>
    <div class="mt-2">
      <div class="shadow-md rounded-xl bg-gray-50 p-4 w-full mt-4" v-for="product in products">
        <div class="flex justify-between w-full mb-2">
          <div class="flex items-center">
            <span class="uppercase font-semibold mr-2">{{ product.name }}</span>
            <span v-if="product.is_in_stock" class="text-green-700 rounded-full bg-green-300 px-3 py-1 text-xs select-none">
              In Stock
            </span>
            <span v-else class="text-red-500 rounded-full bg-red-200 px-3 py-1 text-xs select-none">
              Out Of Stock
            </span>
          </div>
          <div class="flex items-center gap-2">
            <button v-for="color in product.specs" @click="openColor(product, color)" class="w-1 h-1 rounded-full px-3 py-1 text-xs select-none" :class="color.class_name"></button>
          </div>
          <div class="flex item-center gap-4">
            <div class="text-lg">{{ product.price }} €</div>
            <button
                class="bg-gray-600 border border-transparent rounded-lg text-white px-2 hover:bg-coral"
                @click="openColor(product)">
              New color
            </button>
            <button
                class="bg-gray-600 border border-transparent rounded-lg text-white px-2 hover:bg-coral"
                @click="openSize(product)">
              Add size
            </button>
            <PencilIcon
                @click.prevent="openProduct(product)"
                class="h-6 w-6 text-blue-800 mx-auto cursor-pointer"
            ></PencilIcon>
            <DeleteProduct :product="product"></DeleteProduct>
            <update-product-modal v-if="product.open_product" :product="product" :categories="categories" @customChange="switchOpenProduct">
              Update a product
            </update-product-modal>
            <color-modal v-if="product.open_color && selectedColor" :product="product" :color="selectedColor" @customChange="switchOpenColor">
              Add a color
            </color-modal>
            <color-modal v-else-if="product.open_color" :product="product" @customChange="switchOpenColor">
              Add a color
            </color-modal>
            <size-modal v-if="product.open_size && selectedSize" :product="product" :size="selectedSize" @customChange="switchOpenSize">
              Add a size
            </size-modal>
            <size-modal v-else-if="product.open_size" :product="product" @customChange="switchOpenSize">
              Add a size
            </size-modal>
          </div>
        </div>

        <div class="w-full">
          <table class="min-w-full divide-y divide-gray-200">
              <!-- TABLE HEAD -->
              <thead class="bg-gray-50">
              <tr>
                <th
                    scope="col"
                    class="hidden sm:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  COLOR
                </th>
                <th
                    scope="col"
                    class="hidden sm:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  NAME
                </th>
                <th
                    scope="col"
                    class="hidden sm:table-cell px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  SIZE
                </th>
                <th
                    scope="col"
                    class="hidden sm:table-cell px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  STOCK
                </th>
              </tr>
              </thead>
            <tbody class="bg-white">
            <tr v-for="size in product_specs(product)" class="hover:bg-gray-50 duration-300">
              <td class="w-16 px-6 py-4 whitespace-nowrap"><span class="w-1 h-1 rounded-full px-3 py-1 text-xs select-none" :class="size.class_name"></span></td>
              <td class="hidden sm:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                <div class="flex justify-start items-center">
                  {{ size.spec_name }}
                  <div class="ml-2">
                    <span v-if="size.stock" class="text-green-700 rounded-full bg-green-300 px-3 py-1 text-xs select-none">
                      In Stock
                    </span>
                    <span v-else class="text-red-500 rounded-full bg-red-200 px-3 py-1 text-xs select-none">
                      Out Of Stock
                    </span>
                  </div>

                </div>
              </td>
              <td class="hidden sm:table-cell px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">{{ size.size }}</td>
              <td class="hidden sm:table-cell px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                <div class="w-full flex items-center">
                  <div class="w-1/2 text-right px-2">
                    {{ size.stock }}
                  </div>
                  <div class="w-1/2 flex items-center">
                    <a @click="decrementStock(size.id, size.stock)">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </a>
                    <a @click="incrementStock(size.id, size.stock)">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </a>
                  </div>
                </div>
              </td>
              <td>
                <div class="flex justify-between">
                  <PencilIcon
                      @click.prevent="openSize(product, size)"
                      class="h-6 w-6 text-blue-800 mx-auto cursor-pointer"
                  ></PencilIcon>
                  <DeleteSize :size="size"></DeleteSize>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import axios from "axios";
import {createToast} from "mosha-vue-toastify";
import Smallcard from "@/components/Smallcard";
import ProductModal from "@/components/ProductModal";
import ColorModal from "@/components/ColorModal";
import SizeModal from "@/components/SizeModal";
import DeleteSize from "@/components/DeleteSize";
import { PencilIcon } from '@heroicons/vue/solid';
import DeleteProduct from "@/components/DeleteProduct";
import UpdateProductModal from "@/components/UpdateProductModal";

export default {
  name: "Admin",
  components: {UpdateProductModal, DeleteProduct, DeleteSize, SizeModal, ColorModal, ProductModal, Smallcard, PencilIcon},
  data() {
    return {
      categories: [],
      products: [],
      category: "all",
      open_product: false,
      open_color: false,
      open_size: false,
      selectedColor: null,
      selectedSize: null,
      selectedProduct: null,
    }
  },
  watch: {
    // whenever question changes, this function will run
    category(newCat, oldCat) {
      this.filterCategory()
    },
    categories(newCat, oldCat) {
      this.filterCategory()
    }
  },
  async mounted() {
    document.title = 'Admin | Durban'
    await this.getCategories()
    await this.filterCategory()
  },
  methods: {
    async getCategories() {
      this.$store.commit('setIsLoading', true);
      await axios
          .get('/api/v1/products/categories/')
          .then(response => {
            this.categories = response.data;

          })
          .catch(error => {
            console.log(error);

            createToast('Something went wrong. Please Try again.',
                {
                  position: 'top-right',
                  type: 'danger',
                  transition: 'bounce',
                })
          });


      this.$store.commit('setIsLoading', false);
    },
    filterCategory() {
      if (this.category === "all") {
        let prods = []
        this.categories.forEach(category => {
          category.products.forEach(product => {
            prods.push(product)
          })
        })
        this.products = prods
      } else {
        this.products = this.category.products
      }
    },
    product_specs(product) {
      let product_specs = []
      product.specs.forEach(spec => {
            spec.sizes.forEach(size => {
                  product_specs.push({
                    spec_name: spec.name,
                    class_name: spec.class_name,
                    id: size.id,
                    size: size.size,
                    stock: size.stock
                  })
                }
            )
          }
      )
      return product_specs
    },
    decrementStock(id, stock) {
      stock -= 1;
      if (stock >= 0) {
        this.updateSpec(id, stock)
      }
    },
    incrementStock(id, stock) {
      stock += 1;
      this.updateSpec(id, stock)
    },
    async updateSpec(id, stock) {
      await axios.post(`/api/v1/product/sizes/${id}`, {stock: stock})
          .then(response => {
            this.categories = response.data
          })
          .catch(error => {
            console.log(error);

            createToast('Something went wrong. Please Try again.',
                {
                  position: 'top-right',
                  type: 'danger',
                  transition: 'bounce',
                })
          })
    },
    async switchOpen(value) {
      this.open_product = value
    },
    openProduct(product) {
      product.open_product = true
    },
    openColor(product, color=null) {
      if (color !== null) {
        this.selectedColor = color
      }
      product.open_color = true

    },
    openSize(product, size) {
      if (size !== null) {
        this.selectedSize = size
      }
      product.open_size = true
    },
    async switchOpenColor(product) {
      product.open_color = false
      this.selectedColor = null
    },
    async switchOpenSize(product) {
      product.open_size = false
      this.selectedSize = null
    },
    async switchOpenProduct(product) {
      product.open_product = false
      this.selectedProduct = null
    }
  }
}
</script>

<style scoped>

</style>
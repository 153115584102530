<template>
  <!-- Modal -->
  <TransitionRoot as="template" :show="openSwitch">
    <Dialog
        as="div"
        class="fixed z-40 inset-0 overflow-y-auto"
        @close="handleChange"
    >
      <div
          class="
          flex
          items-end
          justify-center
          min-h-screen
          pt-4
          px-4
          pb-20
          text-center
          sm:block
          sm:p-0
        "
      >
        <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
        >
          <DialogOverlay
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
        >&#8203;</span
        >
        <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
              class="
              inline-block
              align-bottom
              bg-white
              rounded-lg
              px-4
              pt-5
              pb-4
              text-left
              overflow-hidden
              shadow-xl
              transform
              transition-all
              sm:my-8
              sm:align-middle
              sm:max-w-lg
              sm:w-full
              sm:p-6
              relative
            "
          >
            <div class="sm:block absolute top-0 right-0 pt-4 pr-4 z-9">
              <button
                  type="button"
                  class="bg-white rounded-md text-gray-400 hover:text-gray-500"
                  @click="handleChange"
              >
                <span class="sr-only">Close</span>
                <XIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div>
              <div class="mt-3 text-center sm:mt-5">
                <DialogTitle
                    as="h3"
                    class="text-lg leading-6 font-medium text-gray-900"
                >
                  <slot />
                </DialogTitle>
                <div class="mt-2">
                  <form @submit.prevent="saveSpec" class="text-left">

                    <div class="mt-6">
                      <label
                          for="categories"
                          class="block text-sm font-medium text-gray-500"
                      >
                        Color<sup>*</sup>
                      </label>

                      <!-- Colors -->
                      <div>
                        <div class="w-72">
                          <Listbox v-model="selectedColor">
                            <div class="relative mt-1">
                              <ListboxButton
                                  class="relative flex justify-between w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
                              >
                                <span class="block truncate">{{ selectedColor.name }}</span>
                                <span class="w-1 h-1 rounded-full px-3 py-1 text-xs select-none" :class="selectedColor.class_name"></span>
                                <span
                                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                                >
                                  <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                              </ListboxButton>

                              <transition
                                  leave-active-class="transition duration-100 ease-in"
                                  leave-from-class="opacity-100"
                                  leave-to-class="opacity-0"
                              >
                                <ListboxOptions
                                    class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                >
                                  <ListboxOption
                                      v-slot="{ active, selected }"
                                      v-for="color in colors"
                                      :key="color.name"
                                      :value="color"
                                      as="template"
                                  >
                                    <li
                                        :class="[active ? 'bg-amber-100 text-amber-900' : 'text-gray-900',
                                        'relative flex justify-between cursor-default select-none py-2 pl-10 pr-4',]"
                                    >
                                      <span
                                        :class="[
                                        selected ? 'font-medium' : 'font-normal',
                                        'block truncate',
                                      ]"
                                      >{{ color.name }}</span>
                                      <span class="w-1 h-1 rounded-full px-3 py-1 text-xs select-none" :class="color.class_name"></span>
                                      <span
                                          v-if="selected"
                                          class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                                      >
                                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    </li>
                                  </ListboxOption>
                                </ListboxOptions>
                              </transition>
                            </div>
                          </Listbox>
                        </div>
                      </div>

                    </div>

                    <div class="mt-6">
                      <label
                          for="name"
                          class="block text-sm font-medium text-gray-500"
                      >
                        Size<sup>*</sup>
                      </label>
                      <div class="mt-1 h-12">
                        <input
                            required
                            id="name"
                            name="name"
                            type="text"
                            autocomplete="name"
                            v-model="size.size"
                            placeholder="Enter the size"
                            class="
                            appearance-none
                            block
                            w-full
                            px-3
                            py-2
                            border border-gray-300
                            rounded-md
                            shadow-sm
                            placeholder-gray-400
                            focus:outline-none
                            focus:ring-atol-blue-electric
                            focus:border-atol-blue-electric
                            sm:text-sm
                            h-12
                          "
                        />
                      </div>
                    </div>

                    <div class="mt-6">
                      <label
                          for="price"
                          class="block text-sm font-medium text-gray-500"
                      >
                        Stock<sup>*</sup>
                      </label>
                      <div class="mt-1 h-12">
                        <input
                            required
                            id="price"
                            name="price"
                            type="number"
                            autocomplete="price"
                            v-model="size.stock"
                            placeholder="0"
                            step="1"
                            min="0"
                            class="
                            appearance-none
                            block
                            w-full
                            px-3
                            py-2
                            border border-gray-300
                            rounded-md
                            shadow-sm
                            placeholder-gray-400
                            focus:outline-none
                            focus:ring-atol-blue-electric
                            focus:border-atol-blue-electric
                            sm:text-sm
                            h-12
                          "
                        />
                      </div>
                    </div>

                    <div
                        class="
                        mt-5
                        sm:mt-6
                        sm:grid sm:grid-cols-2
                        sm:gap-3
                        sm:grid-flow-row-dense
                        text-red-700
                      "
                        v-if="errors.length"
                    >
                      <p v-for="error in errors" v-bind:key="error">
                        {{ error }}
                      </p>
                    </div>

                    <div class="flex gap-2 mt-4">
                      <u-i-button
                          class="flex-1"
                          kind="danger"
                          as="button"
                          @click="handleChange"
                      >Cancel</u-i-button
                      >
                      <u-i-button
                          class="flex-1"
                          kind="save"
                          as="button"
                          type="submit"
                      >Save</u-i-button
                      >
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  DialogOverlay,
  Dialog,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue'
import { CheckIcon, SelectorIcon, XIcon } from '@heroicons/vue/solid'
import UIButton from "@/components/UIButton";
import axios from "axios";
import {createToast} from "mosha-vue-toastify";

export default {
  name: "SizeModal",
  components: {
    DialogOverlay,
    Dialog,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    UIButton,
    Listbox,
    ListboxLabel,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    XIcon,
    CheckIcon,
    SelectorIcon,
  },
  props: {
    product: {
      default: {
        id: 0,
        name: '',
        category: '',
        slug: '',
        description: '',
        price: 0.00,
        // Thumbnail: null
      }
    },
    size: {
      default: {
        size: '',
        stock: 0
      }
    }
  },
  emits: ["customChange"],
  async mounted() {
    await this.getColors()
    this.selectedColor = this.colors[0]
  },
  data() {
    return {
      openSwitch: true,
      images: [],
      errors: [],
      colors: [
        'bg-coral',
        'bg-gray-500',
        'bg-blue-500',
        'bg-green-500'
      ],
      selectedColor: {},
      selectedSize: {
        name: '',
        class_name: ''
      },
    }
  },
  methods: {
    async getColors() {
      this.colors = await axios.get(`/api/v1/product/${this.product.id}/specs`)
          .then(response => {
            return response.data
          })
          .catch(error => {
            console.log(error);

            createToast('Something went wrong. Please Try again.',
                {
                  position: 'top-right',
                  type: 'danger',
                  transition: 'bounce',
                })
            return []
      })
    },
    getFirstColor() {
      this.selectedColor = this.colors[0]
    },
    handleChange() {
      this.$emit("customChange", this.product)
    },
    saveSpec() {
      let payload = new FormData();
      this.$store.commit('setIsLoading', true)
      payload.append('size', JSON.stringify(this.size))
      axios.post(`/api/v1/product/colors/${this.selectedColor.id}/size/`, payload)
          .then(response => {
            this.$router.go()
            this.handleChange()
          })
          .catch(error => {
            console.log(error);

            createToast('Something went wrong. Please Try again.',
                {
                  position: 'top-right',
                  type: 'danger',
                  transition: 'bounce',
                })
          })
      // TODO Remove hard reload to update cache
      this.$store.commit('setIsLoading', false)
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <TrashIcon
      @click.prevent="openModal"
      class="h-6 w-6 text-red-500 mx-auto cursor-pointer"
  ></TrashIcon>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal">
      <div class="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-40">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
          >
            <div
                class="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
            >
              <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 text-gray-900 text-center"
              >
                Delete Color
              </DialogTitle>
              <div class="mt-2">
                <p class="text-sm text-gray-500 text-center">
                  Are you sure? This color and its sizes will be deleted forever.
                </p>
              </div>
              <div class="flex flex-wrap justify-center mt-4 text-center">
                <u-i-button class="uppercase m-1" kind="white" @click="closeModal"
                >Cancel</u-i-button
                >
                <u-i-button
                    class="uppercase m-1"
                    kind="danger"
                    @click="delSpecs(color.id)"
                >Confirm</u-i-button
                >
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from '@headlessui/vue'
import { PencilIcon, UserRemoveIcon, TrashIcon } from '@heroicons/vue/solid'
import { createToast } from 'mosha-vue-toastify'
import { mapActions } from 'vuex'
import UIButton from "@/components/UIButton";
import axios from "axios";

export default {
  components: {
    UIButton,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    PencilIcon,
    UserRemoveIcon,
    TrashIcon
  },
  props: {
    color: {
      default: null,
    },
  },
  methods: {
    async delSpecs() {
      this.$store.commit('setIsLoading', true)
      await axios.delete(`/api/v1/specs/${this.color.id}/`)
          .then(response => {
            console.log(response)
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                createToast(`${error.response.data[property]}`, {
                  position: 'top-right',
                  type: 'danger',
                  transition: 'bounce',
                })
              }
              console.log(JSON.stringify(error.response.data))
            } else if (error.message) {
              console.log(error.message)
              createToast('Something went wrong. Please Try again.', {
                position: 'top-right',
                type: 'danger',
                transition: 'bounce',
              })
            }
          })
      this.closeModal()
      // TODO Remove hard reload to update cache
      this.$router.go()
      this.$store.commit('setIsLoading', false)
    },
  },
  setup() {
    const isOpen = ref(false)

    return {
      isOpen,
      closeModal() {
        isOpen.value = false
      },
      openModal() {
        isOpen.value = true
      },
    }
  },
}
</script>

<style scoped>
.icon-mobile {
  display: none;
}

.icon-desktop {
  display: block;
}

@media only screen and (max-width: 1200px) {
  .icon-mobile {
    display: block;
  }

  .icon-desktop {
    display: none;
  }
}
</style>

<template>
  <!-- Modal -->
  <TransitionRoot as="template" :show="openSwitch">
    <Dialog
        as="div"
        class="fixed z-50 inset-0 overflow-y-auto"
        @close="handleChange"
    >
      <div
          class="
          flex
          items-end
          justify-center
          min-h-screen
          pt-4
          px-4
          pb-20
          text-center
          sm:block
          sm:p-0
        "
      >
        <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
        >
          <DialogOverlay
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
        >&#8203;</span
        >
        <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
              class="
              inline-block
              align-bottom
              bg-white
              rounded-lg
              px-4
              pt-5
              pb-4
              text-left
              overflow-hidden
              shadow-xl
              transform
              transition-all
              sm:my-8
              sm:align-middle
              sm:max-w-lg
              sm:w-full
              sm:p-6
              relative
            "
          >
            <div class="sm:block absolute top-0 right-0 pt-4 pr-4 z-9">
              <button
                  type="button"
                  class="bg-white rounded-md text-gray-400 hover:text-gray-500"
                  @click="handleChange"
              >
                <span class="sr-only">Close</span>
                <XIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div>
              <div class="mt-3 text-center sm:mt-5">
                <DialogTitle
                    as="h3"
                    class="text-lg leading-6 font-medium text-gray-900"
                >
                  <slot />
                </DialogTitle>
                <div class="mt-2">
                  <form @submit.prevent="saveProduct" class="text-left">

                    <div class="mt-6">
                      <label
                          for="categories"
                          class="block text-sm font-medium text-gray-500"
                      >
                        Category<sup>*</sup>
                      </label>
                      <select required name="categories" id="categories" v-model="product.category" class="rounded-xl">
                        <option v-for="cat in categories" :value="cat">{{ cat.name }}</option>
                      </select>
                    </div>

                    <div class="mt-6">
                      <label
                          for="name"
                          class="block text-sm font-medium text-gray-500"
                      >
                        Name<sup>*</sup>
                      </label>
                      <div class="mt-1 h-12">
                        <input
                            required
                            id="name"
                            name="name"
                            type="text"
                            autocomplete="name"
                            v-model="product.name"
                            placeholder="Enter the product name"
                            class="
                            appearance-none
                            block
                            w-full
                            px-3
                            py-2
                            border border-gray-300
                            rounded-md
                            shadow-sm
                            placeholder-gray-400
                            focus:outline-none
                            focus:ring-atol-blue-electric
                            focus:border-atol-blue-electric
                            sm:text-sm
                            h-12
                          "
                        />
                      </div>
                    </div>

                    <div class="mt-6">
                      <label
                          for="slug"
                          class="block text-sm font-medium text-gray-500"
                      >
                        Slug<sup>*</sup>
                      </label>
                      <div class="mt-1 h-12">
                        <input
                            required
                            id="slug"
                            name="slug"
                            type="text"
                            autocomplete="slug"
                            v-model="product.slug"
                            placeholder="Enter the product slug"
                            class="
                            appearance-none
                            block
                            w-full
                            px-3
                            py-2
                            border border-gray-300
                            rounded-md
                            shadow-sm
                            placeholder-gray-400
                            focus:outline-none
                            focus:ring-atol-blue-electric
                            focus:border-atol-blue-electric
                            sm:text-sm
                            h-12
                          "
                        />
                      </div>
                    </div>

                    <div class="mt-6">
                      <label
                          for="description"
                          class="block text-sm font-medium text-gray-500"
                      >
                        Description<sup>*</sup>
                      </label>
                      <div class="mt-1 h-36">
                        <textarea
                            required
                            id="description"
                            name="description"
                            autocomplete="description"
                            v-model="product.description"
                            placeholder="Enter the product description"
                            class="
                            appearance-none
                            block
                            w-full
                            px-3
                            py-2
                            border border-gray-300
                            rounded-md
                            shadow-sm
                            placeholder-gray-400
                            focus:outline-none
                            focus:ring-atol-blue-electric
                            focus:border-atol-blue-electric
                            sm:text-sm
                            h-36
                          "
                        />
                      </div>
                    </div>

                    <div class="mt-6">
                      <label
                          for="price"
                          class="block text-sm font-medium text-gray-500"
                      >
                        Price<sup>*</sup>
                      </label>
                      <div class="mt-1 h-12">
                        <input
                            required
                            id="price"
                            name="price"
                            type="number"
                            autocomplete="price"
                            v-model="product.price"
                            placeholder="1.00"
                            step="0.10"
                            min="0"
                            class="
                            appearance-none
                            block
                            w-full
                            px-3
                            py-2
                            border border-gray-300
                            rounded-md
                            shadow-sm
                            placeholder-gray-400
                            focus:outline-none
                            focus:ring-atol-blue-electric
                            focus:border-atol-blue-electric
                            sm:text-sm
                            h-12
                          "
                        />
                      </div>
                    </div>

                    <div class="mt-6">
                      <label
                          for="price"
                          class="block text-sm font-medium text-gray-500"
                      >
                        Picture(s)<sup>*</sup>
                      </label>
                      <div class="mt-1 h-12">
                        <input
                            required
                            type="file"
                            @change="onChange"
                            multiple
                            class="
                            appearance-none
                            block
                            w-full
                            px-3
                            py-2
                            border border-gray-300
                            rounded-md
                            shadow-sm
                            placeholder-gray-400
                            focus:outline-none
                            focus:ring-coral
                            focus:border-coral
                            sm:text-sm
                            h-12
                          "
                        />
                      </div>
                    </div>

                    <div
                        class="
                        mt-5
                        sm:mt-6
                        sm:grid sm:grid-cols-2
                        sm:gap-3
                        sm:grid-flow-row-dense
                        text-red-700
                      "
                        v-if="errors.length"
                    >
                      <p v-for="error in errors" v-bind:key="error">
                        {{ error }}
                      </p>
                    </div>

                    <div class="flex gap-2 mt-4">
                      <u-i-button
                          class="flex-1"
                          kind="danger"
                          as="button"
                          @click="handleChange"
                      >Cancel</u-i-button
                      >
                      <u-i-button
                          class="flex-1"
                          kind="save"
                          as="button"
                          type="submit"
                      >Save</u-i-button
                      >
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Switch,
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  SwitchLabel,
  SwitchGroup
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/solid'
import UIButton from "@/components/UIButton";
import axios from "axios";
import {createToast} from "mosha-vue-toastify";

export default {
  name: "ProductModal",
  components: {
    UIButton,
    Switch,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    SwitchLabel,
    SwitchGroup,
    XIcon
  },
  props: {
    open_product: {
      default: true
    },
    categories: {
      default: []
    },
    product: {
      default: {
        id: 0,
        name: '',
        category: '',
        slug: '',
        description: '',
        price: 0.00,
        // Thumbnail: null
      }
    }
  },
  emits: ["customChange"],
  data() {
    return {
      openSwitch: true,
      images: [],
      errors: []
    }
  },
  methods: {
    onChange(e){
      console.log(e.target.files)
      this.images = e.target.files;
    },
    handleChange() {
      this.$emit("customChange", false)
    },
    saveProduct() {
      this.$store.commit('setIsLoading', true)
      let payload = new FormData();
      for(let i=0; i<this.images.length; i++){
        payload.append(`image_${i}`, this.images[i])
      }
      payload.append('product', JSON.stringify(this.product))
      payload.append('len_images', JSON.stringify(this.images.length))
      axios.post('/api/v1/product/', payload)
          .then(response => {
            this.handleChange()
            this.$router.go()
          })
          .catch(error => {
            console.log(error);
            createToast('Something went wrong. Please Try again.',
                {
                  position: 'top-right',
                  type: 'danger',
                  transition: 'bounce',
                })
          })
      // TODO Remove hard reload to update cache
      this.$store.commit('setIsLoading', false)
    }
  }
}
</script>

<style scoped>

</style>